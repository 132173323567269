<template>
  <el-container style="height: 500px; border: 1px solid #eee">
    <el-header class="header" style="color: white;">
      <div style="display: flex">
        <div style="font-size: 30px"><img height="26px" :src="$store.state.setting.registerpageimage"></div>
        <div class="mg-l10" style="font-size: 25px;">默蓝智能客服系统</div>
        <div v-if="logintype ==1" class="mg-l10" style="font-size: 18px;">
          <router-link to="/server">工作台</router-link>
        </div>
        <div class="mg-l10" style="font-size: 18px;">
          <router-link to="/custindex">管理员后台</router-link>
        </div>
      </div>
      <div style="position: absolute;right: 30px;z-index: 10;top:0px;">
        <div style="display: flex;color: white;font-size: 14px;">
          <div class="mar-r10">
            <el-menu mode="horizontal" style="background-color: #da424e;">
              <el-submenu index="1">
                <template slot="title">
                  <span class="name">账号：{{userName}}</span>
                  <span class="name">组织名称：{{unitname}}</span>
                  <span class="name" v-if="userflag==1">组织类型：个人</span>
                  <span class="name" v-if="userflag==2">组织类型：企业</span>
                </template>
                <el-menu-item index="1-1" @click="updatePwd()">修改密码</el-menu-item>
                <el-menu-item index="1-2" @click="logout()">退出登录</el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu router>
          <el-submenu index="1">
            <template slot="title"><i class="el-icon-user"></i>账号管理</template>
            <el-menu-item index="/userinfo">账号信息</el-menu-item>
          </el-submenu>
          <el-submenu index="2" v-if="logintype==0">
            <template slot="title"><i class="el-icon-menu"></i>项目管理</template>
            <el-menu-item index="/projectinfo">项目信息</el-menu-item>
          </el-submenu>
          <el-submenu index="3" v-if="logintype==0">
            <template slot="title"><i class="el-icon-service"></i>客服管理</template>
            <el-menu-item index="/csstaffinfo">客服信息</el-menu-item>
            <el-menu-item index="/refcsstaffproject">项目客服</el-menu-item>
          </el-submenu>
          <!--<el-submenu index="4" v-if="logintype==0">
            <template slot="title"><i class="el-icon-date"></i>热门问题</template>
            <el-menu-item index="/hotissues">问题管理</el-menu-item>
          </el-submenu>-->
          <el-submenu index="5" v-if="logintype==0">
            <template slot="title"><i class="el-icon-date"></i>快捷回复管理</template>
            <!--  <el-menu-item index="/quicklinks">热点标签回复</el-menu-item>-->
            <el-menu-item index="/csquickreply">客服快捷回复</el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title"><i class="el-icon-message"></i>留言管理</template>
            <el-menu-item index="/leave">留言列表</el-menu-item>
          </el-submenu>
          <el-submenu index="7">
            <template slot="title"><i class="el-icon-message"></i>对话消息管理</template>
            <el-menu-item index="/robotmsgreload">对话消息管理</el-menu-item>
          </el-submenu>
          <el-submenu index="8">
            <template slot="title"><i class="el-icon-message"></i>投诉管理</template>
            <el-menu-item index="/complaint">用户投诉</el-menu-item>
          </el-submenu>
          <el-submenu index="9">
            <template slot="title"><i class="el-icon-message"></i>评价管理</template>
            <el-menu-item index="/clientevaluate">用户评价</el-menu-item>
          </el-submenu>
          <el-submenu index="10" v-if="logintype==0">
            <template slot="title"><i class="el-icon-setting"></i>系统设置</template>
            <el-menu-item index="/autoreply">自动回复</el-menu-item>
            <el-menu-item index="/robotset">机器人设置</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>

    <el-dialog title="修改密码" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px"
               style="width: 500px; margin-left:50px;">
        <el-form-item label="原密码" prop="password">
          <el-input v-model="temp.password" type="password" placeholder="请输入原密码"/>
        </el-form-item>
        <el-form-item label="新密码" prop="newpassword">
          <el-input v-model="temp.newpassword" type="password" placeholder="请输入新密码"/>
        </el-form-item>
        <el-form-item label="确认密码" prop="passwordtwo">
          <el-input v-model="temp.passwordtwo" type="password" placeholder="请输入确认新密码"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="updatePwdDate()">
          保存
        </el-button>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
  import {updatePwd, getUserInfo} from "@/apis";

  export default {
    name: 'custIndex',
    components: {},
    data() {
      return {
        logintype: 0,
        userName: '',
        unitname: '',
        userflag: '',
        dialogFormVisible: false,
        temp: {
          password: null,
          passwordtwo: null,
          newpassword: null,
          auid: null
        }, rules: {
          password: [{required: true, message: '请输入原密码', trigger: 'change'}],
          newpassword: [{required: true, message: '请输入新密码', trigger: 'change'}],
          passwordtwo: [{required: true, message: '请输入确认密码', trigger: 'change'}]
        },
      }
    },
    mounted() {
      console.log(this.user)
      console.log(this.user.account)
      if (this.user) {
        if (this.user.logintype) {
          this.logintype = this.user.logintype
        }
        console.log(this.user)
        if (this.user.account) {
          this.userName = this.user.account
        }
      }
    }, created() {
      this.getUserinfo();
    }, methods: {
      logout() {
        this.$store.commit('account/setUser', null);
        this.$router.push({path: '/login'});
        this.$Bus.$emit("logout");
        localStorage.removeItem("token");
      }, updatePwd() {
        this.dialogFormVisible = true
      }, updatePwdDate() {
        var newpassword = this.temp.newpassword;
        var passwordtwo = this.temp.passwordtwo;
        if (newpassword != passwordtwo) {
          this.$message.error("两次输入密码不一致");
          return false;
        }
        this.temp.adminuid = this.user.userId;
        updatePwd(this.temp).then(res => {
          this.dialogFormVisible = false;
          this.$alert('修改密码成功,重新登录?', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.logout();
            }
          });
        });
      }, getUserinfo() {
        var adminuid = this.user.userId;
        getUserInfo({adminuid}).then(res => {
          this.unitname = res.retdata.unitname
          this.userflag = res.retdata.userflag
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .el-header {
    background-color: #da424e;
    color: #333;
    text-align: center;
    line-height: 60px;
  }

  .el-aside {
    color: #333;
  }

  .el-aside {
    background-color: white;
  }

  .el-main {
    background-color: white;
    margin: 7px;
  }
</style>
<style>
  .header .el-menu {
    border-right: 0px;
    top: 10px;
  }

  .header .el-menu--horizontal > .el-submenu .el-submenu__title {
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid transparent;
    color: white;
  }

  .header .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
    position: static;
    vertical-align: middle;
    margin-left: 8px;
    margin-top: -3px;
    color: white;
  }

  .header .el-menu-item:hover {
    background: #DA4253 !important;
  }

  .header .el-submenu__title:hover {
    background: #DA4253 !important;
  }

  .header .el-menu-item.is-active {
    background: #DA4253 !important;
  }

  .header .el-submenu__title.is-active {
    background: #DA4253 !important;
  }

  .header .el-menu.el-menu--horizontal {
    border-bottom: 0px !important;
  }

  .header .el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
    min-width: 120px !important;
    text-align: center;
  }
  .name{
    padding-left: 10px;
  }
  .el-menu--popup-bottom-start{
    margin-left: 320px;
  }
</style>
